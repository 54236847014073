/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { graphql } from "gatsby";
import { Breadcrumbs, CardLink, Grid, Layout } from "~components";
import { useDocuments } from "~hooks";
import { breakpoint } from "~utils/css";
import { capitalizeString } from "~utils/helpers";

/** ============================================================================
 * @component
 * Article graphql wrapper.
 * @param  {data}     object  Data inherited from Storyblok plugin.
 * @param  {location} object  Data inherited Gatsby @reach/router.
 * @return {node}     <main>  Wrapped StoryblokTemplate JSX.
 */
const MaterialIndex = ({ data, location }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const documents = useDocuments();
  const finishMetadata = documents.getFinishMetadata();

  const [items, setItems] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [metadata, setMetadata] = useState(null);

  // ---------------------------------------------------------------------------
  // variables

  const materials = data?.allMyDirttResource?.edges?.[0]
    ? data.allMyDirttResource.edges.map(({ node }) => node)
    : [];

  const indexType = data?.materialIndex?.type || null;

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!indexType || loaded) {
      return;
    }

    setLoaded(true);

    const childMaterials = materials.filter(
      (material) =>
        material?.content?.type?.toLowerCase() === indexType?.toLowerCase()
    );

    const parsedMaterials = childMaterials.map((material) => ({
      ...material
    }));

    setItems(parsedMaterials);

    //

    let description;
    let storyblokSeo;

    if (finishMetadata?.content) {
      if (finishMetadata?.content?.descriptions?.[0]) {
        const finishData = finishMetadata.content.descriptions.find(
          ({ key }) =>
            key?.toLowerCase() === data?.materialIndex?.type?.toLowerCase()
        );

        description = finishData?.description || null;
        storyblokSeo = finishData?.seo || null;
      }
    }

    setMetadata({
      description,
      seo: storyblokSeo
    });
  }, [loaded, materials]);

  // ---------------------------------------------------------------------------
  // render

  if (!indexType) {
    return <></>;
  }

  return (
    <Layout location={location} seo={metadata?.seo}>
      {/* dog: removed for new resource banner */}
      <Breadcrumbs
        background="transparent"
        color="black"
        location={location}
        rootLabel="RESOURCES"
      />

      <section>
        <article
          css={css`
            position: relative;
            padding: 3rem 0 3rem;
          `}
        >
          <Grid>
            <div
              css={css`
                grid-column: span 12 / span 12;
                position: relative;

                ${breakpoint(`large-tablet`)} {
                  grid-column: span 6 / span 6;
                }
              `}
            >
              {data?.materialIndex?.type_name && (
                <h1
                  className="d2"
                  css={css`
                    margin-bottom: 2rem;
                    text-transform: uppercase;

                    ${breakpoint(`large-tablet`)} {
                      margin-bottom: 0;
                    }
                  `}
                >
                  {capitalizeString(data.materialIndex.type_name)}
                </h1>
              )}
            </div>

            <div
              css={css`
                grid-column: span 12 / span 12;
                position: relative;

                ${breakpoint(`large-tablet`)} {
                  grid-column: span 6 / span 6;
                }
              `}
            >
              {metadata?.description && (
                <p className="h3">{metadata.description}</p>
              )}
            </div>
          </Grid>
        </article>
      </section>

      <section
        css={css`
          width: 100%;
          position: relative;
          padding: 3rem 0;
        `}
      >
        {items?.[0] && (
          <Grid>
            {items.map((item) => {
              const itemData = {
                ...item,
                name: item?.content?.name,
                hoverTagline: item?.content.hover_tagline
              };

              if (item?.content?.colours?.[0]) {
                itemData.image = item.content.colours[0].image;
              }

              //

              return (
                <div
                  key={`material-${item?.full_slug}`}
                  css={css`
                    grid-column: span 12 / span 12;
                    margin-bottom: 2rem;

                    ${breakpoint(`large-tablet`)} {
                      grid-column: span 4 / span 4;
                    }
                  `}
                >
                  <CardLink data={itemData} />
                </div>
              );
            })}
          </Grid>
        )}
      </section>
    </Layout>
  );
};

export default MaterialIndex;

export const query = graphql`
  query MaterialIndex($id: String!) {
    materialIndex(id: { eq: $id }) {
      id
      type
      type_name
    }

    allMyDirttResource(
      filter: { full_slug: { regex: "/finishes-and-materials/(.*)/" } }
    ) {
      edges {
        node {
          id
          full_slug

          content {
            name
            type
            material_description

            colours {
              name
              grade
              image
            }

            properties {
              name
              label

              properties {
                name
                label

                applications
                value
                values {
                  content
                }
              }
            }
          }
        }
      }
    }
  }
`;
